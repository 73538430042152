/* Navigation
================================================== */
.nav {
  width: auto;
  margin: 0;
  padding: 0;

  ul {
    display: none;
    padding: 0;
  }

  li {
    display: flex;
    position: relative;
    margin-bottom: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    @include bp_max(mlarge) {
      width: auto;
      text-align: center;
    }
    &:last-of-type {
      .mod-menu__sub {
        @include bp(xlarge) {
          right: 0;
        }
        li {
          @include bp(xlarge) {
            text-align: right;
          }
        }
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.25rem;
    color: $white;
    font-size: 1rem;
    text-decoration: none;
    line-height: 1.2;
    font-weight: 600;
    text-transform: capitalize;
    @include bp(xlarge) {
      font-size: 0.8rem;
    }
    @include bp(xxxlarge) {
      font-size: 0.9rem;
    }
    &.button {
      margin: 1rem 0 0 0;
      padding: 1rem 1.5rem;
      font-size: 0.9rem;
      font-weight: bold;
      text-transform: uppercase;
      color: $white !important;
      border-radius: 0 !important;
      @include bp(xlarge) {
        margin: 0;
        font-size: 0.8rem;
      }
      @include bp(xxlarge) {
        font-size: 0.9rem;
      }
    }
  }

  a:hover,
  .current > a,
  .active > a {
    color: $secondary-colour;
    &:after {
      border-color: $secondary-colour transparent transparent transparent !important;
    }
  }

  .parent {
    @include bp_max(xlarge) {
      flex-direction: column;
    }
    & > a {
      position: relative;

      &:after {
        content: '';
        display: inline-flex;
        width: 0;
        height: 0;
        margin-left: 0.5rem;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        border-color: $white transparent transparent transparent;
        transition: $global-transition;
      }
    }

    .mod-menu__sub {
      display: none;
      flex-flow: column nowrap;
      justify-self: left;
      list-style: none;
      width: 100%;
      min-width: 200px;
      margin: 0;
      padding: 0;

      @include bp(xlarge) {
        position: absolute;
        top: 100%;
        align-items: flex-start;
      }

      li {
        width: 100%;
        margin: 0;
        @include bp_max(xlarge) {
          justify-content: center;
        }
      }

      a {
        width: 100%;
        font-size: 0.8rem;
        @include bp(xlarge) {
          justify-content: left;
          text-align: left;
        }
      }
    }

    .parent {
      flex-wrap: wrap;
      width: 100%;
      & > a {
        &:after {
          @include bp(xlarge) {
            margin-left: auto;
            rotate: -90deg;
          }
        }
      }
      .mod-menu__sub {
        @include bp(xlarge) {
          right: auto;
          left: 100%;
          top: 0;
        }
      }
    }
  }
  &__logo {
    display: none;
    align-items: center;
    position: absolute;
    left: 0;
    height: 100%;
    transform: translateX(-100%);
    transition: $global-transition;
    @include bp(mlarge) {
      display: flex;
    }
    a {
      padding: 0 0.5rem;
      background-color: $white;
    }
    p {
      display: flex;
      margin: 0;
    }
    img,
    svg {
      max-height: 50px;
      max-width: 200px;
      width: 100%;
      height: auto;
      object-fit: contain;
      object-position: left;
      .text {
        fill: $white;
      }
    }
    .event-details {
      font-size: 0.6rem;
      p {
        justify-content: center;
        color: $secondary-colour !important;
        font-weight: bold;
      }
    }
  }
}

/* Main Menu / Event Menu
================================================== */
.nav--main {
  @include bp(xxxlarge) {
    padding: 0;
  }
  .nav {
    display: none;
    @include bp(xxxlarge) {
      display: flex;
      flex-flow: row nowrap;
    }
    .parent {
      &:hover {
        & > ul {
          display: flex;
        }
      }
    }
  }
  .mod-menu__sub {
    z-index: 100;
    background-color: $primary-colour;
  }
  .nav-active & {
    .nav {
      background-color: $primary-colour;
    }
  }
}

/* Main Menu
================================================== */
.nav--primary {
  @include bp(xxxlarge) {
    margin-left: auto;
  }
  .nav {
    @include bp(xxxlarge) {
      justify-content: flex-end;
    }
    /*li {
      @include bp(xxxlarge) {
        margin: 0 0.5rem;
      }
    }*/
    .button {
      @include bp(xxxlarge) {
        margin: 0 0.5rem;
      }
    }
  }
}

/* Event Menu
================================================== */
.nav--secondary {
  position: relative;
  margin-top: -2px;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background-color: $white;
  box-shadow: 0 0 10px rgba(44, 44, 44, 0.2);
  @include bp(xlarge) {
    padding-right: 5rem;
  }
  .shrink & {
    position: fixed;
  }
  .shrink.has-nav-logo & {
    .nav__logo {
      left: 0.5rem;
      transform: translateX(0);
    }
    .nav,
    .nav-button {
      @include bp(mlarge) {
        margin-left: 240px;
      }
    }
  }
  .nav {
    transition: $global-transition;
    @include bp(xxxlarge) {
      justify-content: space-between;
    }
    a {
      color: $black;
      @include bp(xxxlarge) {
        padding: 2rem 2.5rem;
      }
    }
  }
  .parent {
    > a {
      &:after {
        border-color: $black transparent transparent transparent;
      }
    }
  }
  .mod-menu__sub {
    z-index: 100;
    background-color: $white;
    a {
      padding: 1.25rem;
    }
  }
  a:hover,
  .current > a,
  .active > a {
    color: $quaternary-colour;
    &:after {
      border-color: $quaternary-colour transparent transparent transparent !important;
    }
  }
  .nav-button {
    margin-left: 0;
    margin-right: auto;
    padding: 1.5rem 1rem;
    p {
      color: $black;
    }
    span,
    span:before,
    span:after {
      background-color: $black;
    }
  }
}

// Fix for event demo - create new menu for event
#multiple-events-page-example {
  .nav {
    &--secondary,
    &-overlay--secondary {
      .item-133 {
        order: -1;
      }
      .item-166 {
        display: none;
      }
    }
  }
}

/* Nav Overlay
================================================== */
body.nav-active {
  overflow: hidden;
  scrollbar-gutter: stable;
}
.nav-overlay {
  display: none;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $primary-colour;
  &--primary {
    z-index: 110;
  }
  &--secondary {
    z-index: 120;
  }
  .primary-menu-active &--primary,
  .secondary-menu-active &--secondary {
    display: flex;
  }
  .nav {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 12rem;
    overflow-y: auto;
    scrollbar-gutter: stable;
    padding-left: calc(100vw - 100%);
    li {
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
    }
    a {
      font-size: 1rem !important;
      color: $white;
      &.button {
        margin: 0 0 2rem 0;
        font-size: 0.9rem !important;
        &--select-event {
          margin: 2rem 0 0 0;
        }
        &--menu-space {
          margin-top: 2rem;
        }
      }
    }
    a:hover,
    .current > a,
    .active > a {
      color: $secondary-colour;
    }
    ul.active {
      display: flex;
    }
    .parent {
      > a {
        &:after {
          border-color: $white transparent transparent transparent;
        }
      }
      ul {
        position: static !important;
      }
      &:hover {
        ul {
          @include bp(mlarge) {
            display: flex;
          }
        }
      }
      a {
        justify-content: center !important;
      }
    }
    .mod-menu__sub {
      a {
        font-size: 0.9rem !important;
      }
    }
  }
  .nav-button-wrap {
    margin-left: auto;
  }
  .nav-button {
    display: flex !important;
    margin: 1.5rem 1rem 1.5rem auto;
    &:hover {
      p {
        color: $quaternary-colour;
      }
      span,
      span:before,
      span:after {
        background-color: $quaternary-colour;
      }
    }
    p {
      color: $white;
    }
    span,
    span:before,
    span:after {
      background: $white;
    }
  }
  .social {
    width: 100%;
    padding: 2rem 0;
    ul {
      margin: auto;
      justify-content: center;
    }
    svg {
      * {
        fill: $white;
      }
    }
    a {
      &:hover {
        svg {
          * {
            fill: $quaternary-colour;
          }
        }
      }
    }
  }
}

/* Hamburger / Menu
================================================== */
.nav-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 0 0 0 auto;
  padding: 1rem 1rem 1rem 0.5rem;
  background: transparent;
  border-radius: 0 !important;
  outline: 0;
  cursor: pointer;
  @include bp(xlarge) {
    padding-right: 0;
  }

  &:hover {
    p {
      color: $tertiary-colour;
    }
    span,
    span:before,
    span:after {
      background-color: $tertiary-colour;
    }
  }

  p {
    margin: 0;
    padding: 0 0.5rem 0 0;
    color: $white;
    @include transition($global-transition);
    @include bp_max(medium) {
      font-size: 0.8rem;
    }
  }

  span,
  span:before,
  span:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    width: 2em;
    height: 4px;
    background: $white;
    @include border-radius(1px);
    @include transition($global-transition);
  }

  span {
    position: relative;
    &:before {
      top: -10px;
    }
    &:after {
      top: auto;
      bottom: -10px;
    }
  }

  &--active {
    span {
      background: transparent !important;
      &:before,
      &:after {
        top: 0;
        background: $white;
      }
      &:before {
        @include rotate(45);
      }
      &:after {
        @include rotate(-45);
      }
    }
  }

  @include bp(xxxlarge) {
    display: none;
  }
}

button.nav-button {
  background: transparent;
}
