/* Banner / Slider
================================================== */
#banner {
  padding: 0;
  .n2-ss-slide-limiter {
    max-width: 100% !important;
  }
  .slider-button {
    a {
      transition: $global-transition !important;
    }
  }
  h1 {
    font-size: $banner-heading-mobile !important;
    @include bp(medium) {
      font-size: $banner-heading-tablet !important;
    }
    @include bp(large) {
      font-size: $banner-heading !important;
    }
    #home & {
      font-size: 2.2rem !important;
      @include bp(medium) {
        font-size: 2.8rem !important;
      }
      @include bp(mlarge) {
        font-size: 3.2rem !important;
      }
      @include bp(large) {
        font-size: 3.6rem !important;
      }
    }
  }
}
.banner {
  &--main {
    .n2-ss-slide-background-image {
      mix-blend-mode: exclusion;
      opacity: 0.8;
    }
    .n2-ss-slide-background-color {
      z-index: 7 !important;
      mix-blend-mode: color;
      opacity: 0.5;
      background-color: $secondary-colour-light !important;
    }
    .n2-ss-slide-background {
      background-color: $primary-colour-light;
    }
    .n2-ss-button-container {
      a {
        padding: 1rem 1.5rem !important;
        border-radius: 0 !important;
        font-family: $primary-font !important;
        font-weight: 600 !important;
        letter-spacing: 1px !important;
      }
    }
    .button--has-icon-right {
      .n2-ss-button-container {
        a {
          padding-right: 1rem !important;
        }
      }
    }
    .button--has-icon-left {
      .n2-ss-button-container {
        a {
          padding-left: 1rem !important;
        }
      }
    }
  }
  &__overlay,
  &__video-bg {
    height: 100% !important;
  }
  &__overlay {
    mix-blend-mode: color;
  }
  &__video-bg {
    opacity: 0.8;
    .n2_ss_video_player {
      position: relative;
      overflow: hidden;
      height: 100%;
      iframe {
        box-sizing: border-box;
        height: 65vw !important;
        left: 50% !important;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50% !important;
        width: 200vh !important;
      }
    }
  }
  &--no-overlay {
    .n2-ss-slide-background-color {
      display: none;
    }
  }
  &--article-title {
    h1 {
      margin: 2rem 0;
      color: $white;
      text-transform: uppercase;
      font-weight: $base-heading-font-weight;
      .no-white-header & {
        margin-top: 6rem;
      }
    }
  }
}

/* Slider General Styles
================================================== */
.n2-section-smartslider {
  .n2-bullet {
    transition: $global-transition !important;
    &:hover,
    &.n2-active {
      border-color: $tertiary-colour !important;
    }
  }
  .nextend-arrow {
    & > div {
      transition: $global-transition !important;
      background-color: $light-grey !important;
      &:hover,
      &.n2-active {
        background-color: $secondary-colour !important;
      }
    }
  }
}
