/* Header
================================================== */
header {
  position: absolute;
  top: 0;
  z-index: 90;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(35, 31, 32, 0.5) 100%
  );
  transition: $global-transition;
  @include bp(medium) {
    position: fixed;
  }
  .row {
    max-width: 100%;
    align-items: center;
    @include bp(medium) {
      padding: 0 1rem;
    }
    @include bp(xlarge) {
      padding: 0;
    }
    @include bp(xxlarge) {
      padding: 0 1rem;
    }
  }
  .shrink & {
    background: $primary-colour;
    @include bp_max(medium) {
      position: fixed;
    }
    .header__logo {
      img {
        @include bp(medium) {
          width: 160px;
        }
      }
    }
    .header__wrap {
      @include bp(medium) {
        padding: 0.5rem 0;
      }
    }
  }
  .event-details {
    margin-right: auto;
    @include bp(large) {
      margin-right: 0;
      margin-left: 1rem;
    }
    p {
      margin: 0;
      font-size: 0.8rem;
      color: $white !important;
    }
    &__date {
      font-weight: bold;
    }
  }

  // If has multiple events, dont shrink
  .multiple-events & {
    position: absolute !important;
  }

  // Background fix for no banner padding
  .no-banner & {
    background-color: $primary-colour;
  }
}
.header {
  &__wrap {
    padding: 1.5rem 0;
    transition: $global-transition;
  }
  &__logo {
    flex: none;
    position: relative;
    z-index: 20;
    @include bp(xxlarge) {
      margin-right: 2rem;
    }
    a {
      display: flex;
      flex-direction: column;
      @include bp(large) {
        flex-direction: row;
        align-items: center;
      }
    }
    p {
      margin: 0;
    }
    img {
      display: block;
      width: 240px;
      transition: $global-transition;
      @include bp(mlarge) {
        width: 320px;
      }
    }
  }
}

/* White Header
================================================== */
.white-header {
  header {
    background-color: $white;
    box-shadow: 0 0 10px rgba(44, 44, 44, 0.2);
    .shrink & {
      background: $white;
    }
    .no-banner & {
      background-color: $white;
    }
  }
  .nav--primary {
    a {
      color: $base-font-color;
    }
    a:hover,
    .current > a,
    .active > a {
      color: $secondary-colour;
      &:after {
        border-color: $secondary-colour transparent transparent transparent !important;
      }
    }
    .parent {
      & > a {
        &:after {
          border-color: $base-font-color transparent transparent transparent;
        }
      }
    }
    .mod-menu__sub {
      background-color: $white;
    }
    .nav-active & {
      .nav {
        background-color: $white;
      }
    }
    .nav-button {
      p {
        color: $base-font-color;
      }
      span,
      span:before,
      span:after {
        background: $base-font-color;
      }
    }
  }
}
